import React from 'react'
import classNames from 'classnames'
import { StaticQuery, graphql } from 'gatsby'
import { makeStyles } from '@material-ui/core/styles'
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import Parallax from 'components/Parallax/Parallax.js'
import Button from 'components/CustomButtons/Button.js'
import { Link } from 'gatsby'
import Head from 'components/Head/head'
import Header from 'components/Header/Header.js'
import Footer from 'components/Footer/Footer.js'
import HeaderLinks from 'components/Header/HeaderLinks.js'
import Logo from 'assets/img/logo.png'
import Img from 'gatsby-image';

import layoutPageStyle from 'assets/jss/styles/pages/layoutPageStyle.js'
import { LANDING, BANOS, COCINAS, LOCALES, PISOS, ELECTRICISTAS, FONTANEROS, PRESUPUESTO, COOKIES, PRIVACY, LEGAL,
CONTENEDORES, CARGADORES } from 'constants/routes';

import 'assets/css/base.css'

const useStyles = makeStyles(layoutPageStyle)

const Layout = ({
  content,
  pageTitle,
  pageDescription,
  headerImage,
  headerImageGatsby,
  filter,
  children,
  contactUs,
  bgImgStyle,
  small = true,
  ...data
}) => {
  const classes = useStyles()
  
  return (
    <div>
      <Head pageTitle={pageTitle || data.siteTitle} pageDescription={pageDescription || data.siteDescription} />
      <Header
        color="primary"
        brand={data.siteTitle}
        links={<HeaderLinks dropdownHoverColor="primary" />}
        fixed
        // changeColorOnScroll={{
        //   height: 300,
        //   color: 'primary',
        // }}
      />

      {contactUs ?
        <div className={classes.bigMap}>
          {contactUs}
        </div>
      :
        <Parallax gatsbyImage={headerImageGatsby} image={headerImage} filter={filter} small={small} style={bgImgStyle}>
          <div className={classes.container}>
            <GridContainer>
              <GridItem xs={12}>
                {content}
              </GridItem>
            </GridContainer>
          </div>
        </Parallax>
      }

      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>{children}</div>
      </div>

      <Footer
        theme="transparent"
        content={
          <div>
            <div className={classNames(classes.pullCenter, classes.copyRight)}>
              Copyright &copy; {1900 + new Date().getYear()}{' '}
              <Link to={LANDING}>{data.siteTitle}</Link> Todos los derechos reservados.
            </div>
          </div>
        }
      >
        <div className={classes.footer}>
          <GridContainer>
            <GridItem xs={12} sm={4} md={4}>
              <GridItem xs={12} sm={8} md={8}>
                <Link to={LANDING}>
                  <img 
                    src={Logo} 
                    className={classes.logo}
                    width="100%"
                    alt="Logotipo Inmovalia Group"
                    title="Logotipo Inmovalia Group"
                  />
                </Link>
                <p>Reformas en Barcelona y alrededores. Reformas integrales de locales comerciales, pisos, cocinas y baños. Equipo con gran experiencia de albañiles, electricistas y fontaneros.</p>
                <form>
                  <Button color="primary">
                    <Link to={PRESUPUESTO} style={{ color: 'white' }}>
                      Pide Presupuesto
                    </Link>
                  </Button>
                </form>
              </GridItem>
            </GridItem>
            <GridItem xs={12} sm={3} md={3}>
              <h5>Construcción</h5>
              <ul className={classes.linksVertical}>
                <li>
                  <Link to={CONTENEDORES}>Casas con contenedores</Link>
                </li>               
              </ul>

              <h5>Instalaciones</h5>
              <ul className={classes.linksVertical}>
                <li>
                  <Link to={CARGADORES}>Cargadores Coches Eléctricos</Link>
                </li>               
              </ul>
            </GridItem>
            <GridItem xs={12} sm={3} md={3}>
              <h5>Reformas</h5>
              <ul className={classes.linksVertical}>
                <li>
                  <Link to={LOCALES}>Reformas Locales Barcelona</Link>
                </li>
                <li>
                  <Link to={PISOS}>Reformas pisos en Barcelona</Link>
                </li>
                <li>
                  <Link to={COCINAS}>Reformas cocinas en Barcelona</Link>
                </li>
                <li>
                  <Link to={BANOS}>Reformas baños en Barcelona</Link>
                </li>
                <li>
                  <Link to={ELECTRICISTAS}>Electricistas Barcelona</Link>
                </li>
                <li>
                  <Link to={FONTANEROS}>Fontaneros Barcelona</Link>
                </li>                
              </ul>
            </GridItem>
            <GridItem xs={12} sm={2} md={2}>
              <h5>Legal</h5>
              <ul className={classes.linksVertical}>
                <li>
                  <Link to={LEGAL}>Nota Legal</Link>
                </li>
                <li>
                  <Link to={PRIVACY}>
                    Política de Privacidad
                  </Link>
                </li>
                <li>
                  <Link to={COOKIES}>Cookies</Link>
                </li>
              </ul>
            </GridItem>
          </GridContainer>
        </div>
      </Footer>
    </div>
  )
}

const LayoutWithQuery = props => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            siteTitle
            siteDescription
          }
        }
      }
    `}
    render={ data => <Layout {...data.site.siteMetadata} {...props} /> }
  />
)

export default LayoutWithQuery
